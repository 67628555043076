import { useState } from "react";
import "../assets/css/main.css";
import MenuBox from "./MenuBox";

import Home from "./Home";
import UiUx from "./UiUx";
import Illustration from "./Illustration";
import Animation from "./Animation";
import Landscapes from "./Landscapes";
import Contact from "./Contact";

const PortfolioApp = () => {
  const [siteStatus, setSiteStatus] = useState({
    home: true,
    uiux: false,
    illustration: false,
    animation: false,
    landscapes: false,
    contact: false,
    sectionClass: "home",
  });

  const handleMenuSelect = (whichSection) => {
    // Set all sections to false except the selected one
    const updatedSections = Object.keys(siteStatus).reduce((acc, section) => {
      if (section === "sectionClass") {
        // Preserve sectionClass
        return acc;
      } else {
        return { ...acc, [section]: section === whichSection };
      }
    }, {});

    // Update the state
    setSiteStatus({
      ...updatedSections,
      sectionClass: whichSection,
    });

    //console.log(siteStatus);
  };

  return (
    <div className={`container ${siteStatus.sectionClass}`}>
      {siteStatus.home && <Home />}

      {siteStatus.uiux && <UiUx />}

      {siteStatus.illustration && <Illustration />}

      {siteStatus.animation && <Animation />}

      {siteStatus.landscapes && <Landscapes />}

      {siteStatus.contact && <Contact />}

      <MenuBox onMenuSelect={handleMenuSelect} />

      <footer>All contents copyright &copy;2024, Brian Hendrickson</footer>
    </div>
  );
};

export default PortfolioApp;
