import "../assets/css/home.css";
import AnimatedBackground from "./AnimatedBackground"

const Home = () => {
  return (
    <div className="homeContainer">
     
      <AnimatedBackground />

      <div className="leftBox"></div>
      <div className="rightBox"></div>
    </div>

    
  );
};

export default Home;




