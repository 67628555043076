import Beholder from "../assets/illustration/beholder.png";
import Coctmvec from "../assets/illustration/coctmvec.png";
import Colony from "../assets/illustration/colony.jpg";
import Dinoroom from "../assets/illustration/dinoroom.png";
import garganth from "../assets/illustration/Garganth.png";
import Earthscore from "../assets/illustration/earthscore.jpg";
import Eddiecross from "../assets/illustration/eddiecross.png";
import Electromage from "../assets/illustration/Electromage.png";
import Icecolony from "../assets/illustration/icecolony.png";
import Kirvsgorn from "../assets/illustration/kirkvsgorn.png";
import Konradin from "../assets/illustration/konradin.jpg";
import Lizardkin from "../assets/illustration/lizardkin.jpg";
import Mars from "../assets/illustration/jcarter.png";
import Startrekcage from "../assets/illustration/startrek-thecage.png";
import Train from "../assets/illustration/train.jpg";
import Turkeyrun from "../assets/illustration/turkeyrun.png";
import CocDetective from "../assets/illustration/cocDetective.png";

const Illustration = () => {
  return (
    <div className="scrollBox">
      <div className="imgScroller">
        <section>
          <img src={Kirvsgorn} alt="kirk vs gorn" />

          <div className="imgInfo">
            <h3>Kirk Vs. Gorn</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Eddiecross} alt="Eddie Cross" />

         <div className="imgInfo">
            <h3>Eddie Cross</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Coctmvec} alt="Call of Cthulhu, the Musical" />
         <div className="imgInfo">
            <h3>Call of Cthulhu, the Musical</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Colony} alt="Colony" />
         <div className="imgInfo">
            <h3>Colony</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={CocDetective} alt="The Last Investigator" />
         <div className="imgInfo">
            <h3>The Last Investigator</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Dinoroom} alt="Adventures in Science" />
         <div className="imgInfo">
            <h3>Adventures in Science</h3>
            <h4>Procreate</h4>
          </div>
        </section>

        <section>
          <img src={garganth} alt="Garganth the Accursed" />
         <div className="imgInfo">
            <h3>Garganth the Accursed</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Earthscore} alt="At the Earth's Core" />

         <div className="imgInfo">
            <h3>At the Earth's Core</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Electromage} alt="Electromage" />
         <div className="imgInfo">
            <h3>Electromage</h3>
            <h4>Procreate</h4>
          </div>
        </section>

        <section>
          <img src={Beholder} alt="beholder" />
         <div className="imgInfo">
            <h3>Beholder</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Icecolony} alt="Ice Colony" />
         <div className="imgInfo">
            <h3>Ice Colony</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Lizardkin} alt="Lizardkin" />
         <div className="imgInfo">
            <h3>Lizardkin</h3>
            <h4>Procreate</h4>
          </div>
        </section>

        <section>
          <img src={Mars} alt="Warlord of Mars" />
         <div className="imgInfo">
            <h3>Warlord of Mars</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Startrekcage} alt="The Cage" />
         <div className="imgInfo">
            <h3>The Cage</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Train} alt="End of the Line" />
         <div className="imgInfo">
            <h3>End of the Line</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>

        <section>
          <img src={Turkeyrun} alt="Turkey Run" />
         <div className="imgInfo">
            <h3>Turkey Run State Park</h3>
            <h4>Adobe Illustrator</h4>
          </div>
        </section>

        <section>
          <img src={Konradin} alt="Konradin and Isabella" />
         <div className="imgInfo">
            <h3>Konradin and Isabella</h3>
            <h4>Affinity Designer</h4>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Illustration;
