import Backwoods from "../assets/landscapes/backwoods.jpg";
import Cascade from "../assets/landscapes/cascade.jpg";
import Castaways from "../assets/landscapes/castaways.jpg";
import Fallcreek from "../assets/landscapes/fallcreek.jpg";
import Lakemichigan from "../assets/landscapes/lakemichigan.jpg";
import Lakeside from "../assets/landscapes/lakeside.jpg";

import Lightsnow from "../assets/landscapes/lightsnow.jpg";
import Longshadows from "../assets/landscapes/longshadows.jpg";
import Mccormick from "../assets/landscapes/mccormickcrk.jpg";
import Mist from "../assets/landscapes/mist.jpg";
import Octstream from "../assets/landscapes/octstream.jpg";
import Palenovember from "../assets/landscapes/palenovember.jpg";
import Riverofgold from "../assets/landscapes/riverofgold.jpg";
import Rockyshore from "../assets/landscapes/rockyshore.jpg";

import Scars from "../assets/landscapes/scars.jpg";
import Skyupstream from "../assets/landscapes/skyupstream.jpg";
import Soundofwater from "../assets/landscapes/soundofwater.jpg";
import Sugarcreek from "../assets/landscapes/sugarcreek.jpg";
import Summerrush from "../assets/landscapes/summerrush.jpg";
import Sunlitwoods from "../assets/landscapes/sunlitwoods.jpg";
import Tangled from "../assets/landscapes/tangled.jpg";
import Tangledtree from "../assets/landscapes/tangledtree.jpg";
import Whiteriver from "../assets/landscapes/whiteriver.jpg";

const Landscapes = () => {
  return (
    <div className="scrollBox">
      <div className="imgScroller">
        <section>
          <img src={Backwoods} alt="Backwoods" />{" "}
          <div className="imgInfo">
            <h3>Backwoods</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Cascade} alt="cascade" />{" "}
          <div className="imgInfo">
            <h3>Cascade</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Castaways} alt="Castaways" />{" "}
          <div className="imgInfo">
            <h3>Castaways</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Fallcreek} alt="Fall Creek" />{" "}
          <div className="imgInfo">
            <h3>Fall Creek</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Lakemichigan} alt="Lake Michigan" />{" "}
          <div className="imgInfo">
            <h3>Lake Michigan</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Lakeside} alt="Lakeside" />{" "}
          <div className="imgInfo">
            <h3>Lakeside</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Lightsnow} alt="" />{" "}
          <div className="imgInfo">
            <h3>Light Snow and Shallow Water</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Longshadows} alt="" />{" "}
          <div className="imgInfo">
            <h3>Long Shadows</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Mccormick} alt="" />{" "}
          <div className="imgInfo">
            <h3>McCormick's Creek</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Mist} alt="" />{" "}
          <div className="imgInfo">
            <h3>Mist and Shadows All Around</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Octstream} alt="" />{" "}
          <div className="imgInfo">
            <h3>October Stream</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Palenovember} alt="" />
          <div className="imgInfo">
            <h3>Pale November</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Riverofgold} alt="" />{" "}
          <div className="imgInfo">
            <h3>River of Gold</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Rockyshore} alt="" />{" "}
          <div className="imgInfo">
            <h3>Rocky Shore</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Scars} alt="" />{" "}
          <div className="imgInfo">
            <h3>Scars of a Thousand Storms</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Skyupstream} alt="" />
          <div className="imgInfo">
            <h3>The Sky Upstream</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Soundofwater} alt="" />{" "}
          <div className="imgInfo">
            <h3>Sound of Water</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Sugarcreek} alt="" />{" "}
          <div className="imgInfo">
            <h3>Sugar Creek</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Summerrush} alt="" />{" "}
          <div className="imgInfo">
            <h3>Summer Rush</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Sunlitwoods} alt="" />{" "}
          <div className="imgInfo">
            <h3>Sunlit Woods</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Tangled} alt="" />
          <div className="imgInfo">
            <h3>Tangled</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Tangledtree} alt="" />
          <div className="imgInfo">
            <h3>Twisted</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>

        <section>
          <img src={Whiteriver} alt="" />{" "}
          <div className="imgInfo">
            <h3>White River</h3>
            <h4>Egg Tempera</h4>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Landscapes;
