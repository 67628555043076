import React, { useState } from 'react';
import "../assets/css/contact.css";
import axios from 'axios';
import SiteData from "./SiteData";



const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name && formData.email && formData.message) {

      console.log('Form submitted:', formData); 

      axios.post('http://localhost:3001/write-file', { formData })
      .then(response => {
        console.log("response:");
        console.log(response);
        setMessage(response.data.message);
        
      })
      .catch(error => {
        console.error('There was an error writing the file!', error);
        setMessage('Error writing the file');
      });

   

      setSubmitted(true);
    } else {
      alert('Please fill in all fields.');
    }
  };

  return (
    <div className='contactContainer'>

      {submitted ? (
        
        <p className="contactThanks">{message} -- Thank you for your message.</p>
      ) : (
        
        <form onSubmit={handleSubmit}>
          <SiteData />
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">Send</button>
        </form>

        
      )}


    </div>
  );
};

export default Contact;
