
const Animation = () => {
    return (
      <div className="contactContainer">
        Animation goes here
      </div>
    );
  };
  
  
  
  export default Animation;
  
  
  
  
  