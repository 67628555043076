import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SiteData = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    axios.get('http://localhost:3001/')
      .then(response => {
        console.log(response.data)
        setMessage(response.data.message);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  return (
    <div>
      <h3>{message}</h3>
    </div>
  );
};

export default SiteData;
