import AVdesigns from "../assets/uiDesign/AVdesigns.png"
import dwellingLive from "../assets/uiDesign/dwellingLive.png"

import ehs from "../assets/uiDesign/ehs.png"
import mobileGate1 from "../assets/uiDesign/mobileGate1.png"
import mobileVio2 from "../assets/uiDesign/mobileVio2.png"
import patrolLive1 from "../assets/uiDesign/patrolLive1.png"
import rps from "../assets/uiDesign/rps.png"

const UiUx = () => {
  return (
    <div className="scrollBox">
      <div className="scrollBoxGroup">


        <section className="intro">
  
          <h2>UI / UX Overview</h2>

          <div className="columnContainer">
            <div className="column">
              <h3> UI Design</h3>
              <em>It's about how the product looks.</em>
              <p>
                User Interface Design focuses on the visual
                and interactive elements of a product,
                like layout, graphics, and aesthetics.
              </p>

            </div>
            <div className="column">
              <h3>UX Design</h3>
              <em>It's about how the product works and feels to the user.</em>
              <p>
                User Experience Design is concerned with the
                overall experience of the user, including
                ease of use, efficiency, and satisfaction.
              </p>
             
            </div>
          </div>
          <p>My involvement with UI / UX Design predates the invention of the term, honestly. I have participated in the
            evolution of human-centered UI design on a first-hand basis and adopted the associated web technologies
            along the way.</p>

          </section>
          
          <section className="designCode">
          <h2>Design / Code</h2>
          <div className="columnContainer">
          <div className="column">
        

          <p>
            I am a designer or a front end developer, depending on who you ask. I enjoy and am comfortable
            in either role, though my more recent projects have been much more code-focused. I have found
            being effective at both tasks has many advantages:
          </p>

            <ul>
          <li>
            It allows for easier design-to-code transition, particularly - as one might imagine -
            in those instances where I am both designer and coder concurrently. 
            </li>
          
            <li>
            I can prioritize end-user experience in design and development. 
            </li>

            <li>
            I can produce graphic assets as needed and optimized for code from inception. 
            </li>

            <li>
            I can effectively negotiate a design team's push for innovative human-centered design and a development 
            team's standards for organization and structure.
            </li>
            </ul>
         
      

          </div>
          <div className="column">
          <ul>
            <li>HTML/CSS</li>
            <li>CSS3 - Flexbox, Grid, responsive design, animations</li>
            <li>Bootstrap</li>
            <li>JavaScript, ES6, JQuery</li>
            <li>SASS/SCSS</li>
            <li>Animation libraries - Three.js, GSAP, anime.js, Velocity.js</li>
            <li>React.js, Redux </li>
            <li>Vue.js, Vuex</li>
            <li>Angular, Kendo components</li>
            <li>Git</li>
            <li>npm, Yarn</li>
          </ul>
          </div>
          </div>
      </section>

      <section >

          <h2>SaaS Applications</h2>

          <img src={AVdesigns} alt="" />
            <div className="slideCaption">
              <h3>Association Voice</h3>
              <p>
                Association Voice is a customizable HOA membership system  
                using SCSS template files, and JQuery scripts throughout. The member management utilities 
                use Angular JS and Kendo components. 
              </p>
            </div>

      </section>

      <section >

          <h2>SaaS Applications</h2>

          <img src={dwellingLive} alt="" />


            <div className="slideCaption">
              <h3>Dwelling Live</h3>
              <p>
                DwellingLIVE company website. Basic HTML, CSS, vanilla JavaScript with integrated Salesforce components.
              </p>
            </div>

      </section>

      <section >

          <h2>SaaS Applications</h2>

          <img src={rps} alt="" />


          <div className="slideCaption">
              <h3>Real Pro Systems</h3>
              <p>
              A real estate agent web-based CRM. My role on 
              the project was to design and develop the interface using JQuery, 
              Kendo UI components, and Angular JS.
              </p>
            </div>

      </section>


      <section >
          <h2>SaaS Applications</h2>

          <img src={ehs} alt="" />
          <div className="slideCaption">
              <h3>EHS</h3>
              <p>
                This Real Pro Systems integration uses React components, CSS templates and Bootstrap to create 
                custom responsive layouts for each subscriber.
              </p>
            </div>
      </section>

      <section >
          <h2>Mobile Application Development</h2>

          <img src={mobileVio2} alt="" />

          <div className="slideCaption">
            <h3>Mobile Violations</h3>
            <p>
              HOA standard compliance application. I was both the designer
              and front-end programmer for the app using Angular JS and Kendo Mobile components.
            </p>
          </div>

      </section>

      <section >
          <h2>Mobile Application Development</h2>

          <img src={mobileGate1} alt="" />

          <div className="slideCaption">
            <h3>Mobile Gate App</h3>
            <p>
            Gated community access app. I handled the responsive design layout, SVG asset creation, design documentation, and 
            conducted Invision user testing.
            </p>
          </div>

      </section>

      <section >
          <h2>Mobile Application Development</h2>

          <img src={patrolLive1} alt="" />

          <div className="slideCaption">
            <h3>Patrol Live</h3>
            <p>
              More of a design role on this one. Created corporate style guide for 
              forthcoming apps, SVG assets creation in Figma, and conducted Invision user testing.
            </p>
          </div>
      </section>
        


    

      </div>
    </div>
  );
};

export default UiUx;

/*
  <section >
  
          <h2>Working with a Team</h2>

          <p>Each team is a unique blend of individual personalities, talents, and expertise. There are,
            however, some common aspects that I try to bring to every project:</p>

          <ul>
            <li>Devout believer in fostering a cooperative and productive environment.</li>

            <li>Effectively communicates ideas and concepts.</li>

            <li>Attentive to team feedback and user needs.</li>

            <li>Blends technical and design solutions creatively</li>

            <li>Quickly adapts to new challenges and changing project requirements</li>

            <li>Prioritizes end-user experience in design and development</li>

            <li>Willing to share knowledge and expertise, helping other team members.</li>
          </ul>


      </section>
*/
